import { CheckboxGroup, IconCalendar } from '@madpaws/design-system';
import { useFormikContext } from 'formik';
import React from 'react';

import {
  WEEK_DAYS,
  SEARCH_FILTERS_FORM_KEYS as KEYS,
} from '~/components/SearchFiltersForm/constants';

import { transformWeekDaysObjectToString } from '../../utils';
import { DialogFormField } from '../DialogFormField/DialogFormField';

import type { CheckboxGroupOnChange as OnChangeEvent } from '@madpaws/design-system';
import type { ReactElement } from 'react';
import type { WeekDays, SearchFilters } from '~/common/types/search';

type WeekDaysSelectorProps = {
  isCalendarIconHidden?: boolean;
  isLabelVisuallyHidden?: boolean;
  isLegendVisuallyHidden?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
};

export const WeekDaysSelector = ({
  isLabelVisuallyHidden,
  name = 'weekDaysSelector',
  placeholder = 'Mon, Tue, Fri',
  isCalendarIconHidden = true,
  isLegendVisuallyHidden,
  label = 'Day(s)',
}: WeekDaysSelectorProps): ReactElement => {
  const {
    values: {
      chronology: { weekDays },
    },
    setFieldValue,
  } = useFormikContext<SearchFilters>();

  const transformWeekDaysObjectToArray = (weekDaysObj: WeekDays): string[] =>
    Object.entries(weekDaysObj).reduce((previousValue: string[], [key, value]) => {
      if (value) {
        previousValue.push(key);
      }

      return previousValue;
    }, []);

  const transformWeekDaysArrayToObject = (weekDaysArr: string[]): WeekDays => {
    const updatedWeekDays: WeekDays = weekDays;
    Object.keys(weekDays).forEach((day) => {
      updatedWeekDays[day as keyof WeekDays] = false;
      if (weekDaysArr.includes(day)) {
        updatedWeekDays[day as keyof WeekDays] = true;
      }
    });

    return updatedWeekDays;
  };

  const parsedValues = transformWeekDaysObjectToArray(weekDays);

  const handleChange = (event: OnChangeEvent): void => {
    const {
      target: {
        value: { value: fieldValue },
      },
    } = event;

    const updatedWeekDays = transformWeekDaysArrayToObject(fieldValue);
    setFieldValue(KEYS.weekDays, updatedWeekDays);
  };

  const possibleIcon = !isCalendarIconHidden && {
    icon: <IconCalendar />,
  };

  return (
    <DialogFormField
      displayValue={transformWeekDaysObjectToString(weekDays)}
      handleSubmit={(): void => undefined}
      isLabelVisuallyHidden={isLabelVisuallyHidden}
      label={label}
      placeholder={placeholder}
      visuallyHiddenLabel="To change the day selection, click the field or press ENTER key to launch the day selector in a dialog."
      {...possibleIcon}
    >
      <CheckboxGroup
        isLegendVisuallyHidden={isLegendVisuallyHidden}
        legend="Which day(s) would you like to book?"
        name={name}
        onChange={handleChange}
        options={WEEK_DAYS}
        value={{ value: parsedValues }}
      />
    </DialogFormField>
  );
};
