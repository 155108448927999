import { RadioGroup, useLargeFromMediaQuery } from '@madpaws/design-system';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import {
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';
import { BOOKING_TYPE_MARKETPLACE, BOOKING_TYPE_RWB } from '~/components/utils/search';

import { RWB_SERVICE_NAMES, SEARCH_FILTERS_FORM_KEYS as KEYS } from '../../constants';

import type { RadioGroupOnChange } from '@madpaws/design-system';
import type { FieldProps } from 'formik';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

export const BookingTypeField = (): ReactElement => {
  const { setFieldValue } = useFormikContext<SearchFilters>();
  const isLargeFromViewport = useLargeFromMediaQuery();

  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;
  const showExperiment = searchExperimentEnabled && !isLargeFromViewport;

  const handleChange = (event: RadioGroupOnChange): void => {
    const {
      target: {
        value: { value: fieldValue },
      },
    } = event;

    setFieldValue(KEYS.bookingType, fieldValue);
  };

  const legend = showExperiment
    ? 'Do you need this service on a weekly basis?'
    : 'How often do you need it?';
  const options = showExperiment
    ? [
        { value: BOOKING_TYPE_MARKETPLACE, label: 'Just once' },
        { value: BOOKING_TYPE_RWB, label: 'Repeating weekly' },
      ]
    : [
        { value: BOOKING_TYPE_MARKETPLACE, label: 'One-off' },
        { value: BOOKING_TYPE_RWB, label: 'Repeat weekly' },
      ];

  return (
    <Field name={KEYS.bookingType}>
      {({ field: { value }, form }: FieldProps): ReactElement | null => {
        if (!RWB_SERVICE_NAMES.includes(form.values.service.type)) {
          return null;
        }

        return (
          <RadioGroup
            leftLabel=""
            legend={legend}
            name={KEYS.bookingType}
            onChange={handleChange}
            options={options}
            rightLabel=""
            value={{ value }}
            variant="tiled"
          />
        );
      }}
    </Field>
  );
};
