import { CounterGroup, useLargeFromMediaQuery } from '@madpaws/design-system';
import { Field } from 'formik';
import React from 'react';

import { PET_TYPE_INFO, PET_TYPE_LABELS, PET_TYPE_NAMES } from '~/common/constants/app';
import {
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';

import { SEARCH_FILTERS_FORM_KEYS as KEYS } from '../../constants';

import type { FieldProps } from 'formik';
import type { ReactElement } from 'react';

type YourPetsFieldProps = {
  acceptedPetTypesNames?: string[];
  legend?: string;
};

export const YourPetsField = ({
  legend,
  acceptedPetTypesNames,
}: YourPetsFieldProps): ReactElement => {
  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;
  const isLargeFromViewport = useLargeFromMediaQuery();

  const showMobileSearchExperiment = searchExperimentEnabled && !isLargeFromViewport;

  return (
    <Field name={KEYS.petTypes}>
      {({ field: { value }, form: { setFieldValue } }: FieldProps): ReactElement => {
        let options = [
          {
            label: PET_TYPE_LABELS.PUPPY,
            description: PET_TYPE_INFO.PUPPY,
            name: PET_TYPE_NAMES.PUPPY,
            value: Number(value.puppy) || 0,
          },
          {
            label: PET_TYPE_LABELS.SMALL_DOG,
            description: searchExperimentEnabled ? 'Less than 10 kg' : PET_TYPE_INFO.SMALL_DOG,
            name: PET_TYPE_NAMES.SMALL_DOG,
            value: Number(value.smallDog) || 0,
          },
          {
            label: PET_TYPE_LABELS.MEDIUM_DOG,
            description: PET_TYPE_INFO.MEDIUM_DOG,
            name: PET_TYPE_NAMES.MEDIUM_DOG,
            value: Number(value.mediumDog) || 0,
          },
          {
            label: PET_TYPE_LABELS.LARGE_DOG,
            description: PET_TYPE_INFO.LARGE_DOG,
            name: PET_TYPE_NAMES.LARGE_DOG,
            value: Number(value.largeDog) || 0,
          },
          {
            label: PET_TYPE_LABELS.GIANT_DOG,
            description: PET_TYPE_INFO.GIANT_DOG,
            name: PET_TYPE_NAMES.GIANT_DOG,
            value: Number(value.giantDog) || 0,
          },
          {
            label: PET_TYPE_LABELS.CAT,
            name: PET_TYPE_NAMES.CAT,
            value: Number(value.cat) || 0,
          },
          {
            label: PET_TYPE_LABELS.RABBIT,
            name: PET_TYPE_NAMES.RABBIT,
            value: Number(value.creature) || 0,
          },
          {
            label: PET_TYPE_LABELS.BIRD,
            name: PET_TYPE_NAMES.BIRD,
            value: Number(value.bird) || 0,
          },
        ];

        if (acceptedPetTypesNames) {
          options = options.filter((option) => acceptedPetTypesNames.includes(option.name));
        }

        return (
          <CounterGroup
            isLegendVisuallyHidden={showMobileSearchExperiment}
            legend={legend ? legend : 'How many pets do you need to book for?'}
            onChange={(event): void => {
              const {
                target: { value: newValue },
              } = event;

              setFieldValue(`${KEYS.petTypes}.${newValue.name}`, newValue.value);
            }}
            options={options}
          />
        );
      }}
    </Field>
  );
};
