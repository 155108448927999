import { RadioGroup } from '@madpaws/design-system';
import React from 'react';

import {
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';
import { SERVICE_OPTIONS, SERVICE_OPTIONS_V2 } from '~/components/constants';

import type { RadioGroupOnChange } from '@madpaws/design-system';
import type { ReactElement } from 'react';
import type { ServiceOption } from '~/components/constants';

type Props = {
  isLegendVisuallyHidden?: boolean;
  legend: string;
  name: string;
  onChange: (event: RadioGroupOnChange) => void;
  options?: ServiceOption[];
  value: string;
};

export const ServiceTypeSelector = ({
  onChange,
  isLegendVisuallyHidden,
  legend,
  name,
  value,
  options = SERVICE_OPTIONS,
}: Props): ReactElement => {
  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;

  const optionsToShow = searchExperimentEnabled ? SERVICE_OPTIONS_V2 : options;

  return (
    <RadioGroup
      isLegendVisuallyHidden={isLegendVisuallyHidden}
      legend={legend}
      name={name}
      onChange={onChange}
      options={optionsToShow}
      value={{ value }}
      variant="tiledWithImage"
    />
  );
};
