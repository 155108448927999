import { useLargeFromMediaQuery } from '@madpaws/design-system';
import { Field, useFormikContext } from 'formik';
import React, { useContext } from 'react';

import {
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';

import { ServiceTypeSelector } from './ServiceTypeSelector';
import { SEARCH_FILTERS_FORM_KEYS as KEYS } from '../../constants';
import { DialogContext } from '../DialogFormField/DialogFormField';

import type { RadioGroupOnChange } from '@madpaws/design-system';
import type { FieldProps } from 'formik';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';
import type { ServiceOption } from '~/components/constants';

type Props = {
  options?: ServiceOption[];
};

export const ServiceTypeField = ({ options }: Props): ReactElement => {
  const { setFieldValue } = useFormikContext<SearchFilters>();
  const { handleDialogClose } = useContext(DialogContext);
  const isLargeFromViewport = useLargeFromMediaQuery();

  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;

  const handleChange = (event: RadioGroupOnChange): void => {
    const {
      target: {
        value: { value: fieldValue },
      },
    } = event;

    setFieldValue(KEYS.serviceType, fieldValue);

    if (!isLargeFromViewport && searchExperimentEnabled) {
      handleDialogClose?.();
    }
  };

  return (
    <Field name={KEYS.serviceType}>
      {({ field }: FieldProps): ReactElement => {
        const { name, value } = field;
        return (
          <ServiceTypeSelector
            isLegendVisuallyHidden={searchExperimentEnabled && !isLargeFromViewport}
            legend="What service are you looking for?"
            name={name}
            onChange={handleChange}
            options={options}
            value={value}
          />
        );
      }}
    </Field>
  );
};
